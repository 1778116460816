import './SettingsUsersInviteModal.less'

import { Form } from 'antd'
import React, { useContext, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CenterData, inviteUsers } from '../../../../requests'
import { validateRequired } from '../../../../validation'
import { UserContext } from '../../../auth'
import { DatacEmailSelect, DatacFormItem, DatacMessage, DatacModal, DatacOption, DatacSelect } from '../../../common'

interface FormData {
  roleId: string
  centerIds: string[]
  emails: string[]
}

interface Props {
  onClose: () => void
  isOpened: boolean
  onInvitationSent: () => void
  availableRoles: DatacOption[]
  availableCenters: CenterData[]
}

export const SettingsUsersInviteModal: React.FC<Props> = ({
  isOpened,
  onClose,
  onInvitationSent,
  availableRoles,
  availableCenters
}) => {
  const intl = useScopedIntl('')
  const intlModal = useScopedIntl('settings.users.invite_modal')
  const [formInstance] = Form.useForm()
  const [emails, setEmails] = useState<string[]>([])
  const [isSendingInvite, setIsSendingInvite] = useState(false)
  const { user } = useContext(UserContext)

  const closeModal = () => {
    setEmails([])
    onClose()
  }

  const onSubmit = ({ roleId, emails, centerIds }: FormData) => {
    setIsSendingInvite(true)
    inviteUsers(
      { roleId, emails, centerIds },
      {
        onSuccess: () => {
          setIsSendingInvite(false)
          closeModal()
          onInvitationSent()
        },
        onRequestError: code => DatacMessage.genericError(intl, code),
        onEmailTaken: takenEmails => {
          DatacMessage.warning(intlModal('email.validation.taken'), takenEmails.join(', '))
          setIsSendingInvite(false)
          onInvitationSent()
          setEmails(takenEmails)
          formInstance.setFieldsValue({ emails: takenEmails })
        },
        onError: () => setIsSendingInvite(false)
      }
    )
  }

  return (
    <DatacModal
      isOpened={isOpened}
      onClose={closeModal}
      afterClose={() => formInstance.resetFields()}
      title={intlModal('title')}
      submitLabel={intlModal('submit')}
      onSubmit={() => formInstance.submit()}
      loading={isSendingInvite}
      destroyOnClose
    >
      <Form
        className="settings-users-invite-modal__form"
        form={formInstance}
        name="settings-users-invite-form"
        onFinish={onSubmit}
      >
        {user.isSubjectRepositoryEnabled && (
          <DatacFormItem label={intlModal('centers.label')} name="centerIds">
            <DatacSelect
              showSearch
              mode="multiple"
              placeholder={intlModal('centers.placeholder')}
              options={availableCenters.map(center => ({ label: center.abbreviation, value: center.id }))}
            />
          </DatacFormItem>
        )}
        <DatacFormItem
          name="roleId"
          label={intlModal('role.label')}
          validate={validateRequired(intlModal('role.validation'))}
        >
          <DatacSelect options={availableRoles} placeholder={intlModal('role.placeholder')} />
        </DatacFormItem>
        <DatacEmailSelect
          name="emails"
          label={intlModal('email.label')}
          value={emails}
          onChange={setEmails}
          form={formInstance}
        />
      </Form>
    </DatacModal>
  )
}
