import './SettingsUsersDeleteModal.less'

import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { AccountType, UserData, deleteUser } from '../../../../requests'
import { routes } from '../../../../routes'
import { UserContext } from '../../../auth'
import { DatacMessage, DatacModal } from '../../../common'

interface Props {
  isOpened: boolean
  onClose: () => void
  userToDelete: UserData
  onUserDeleted: () => void
}

export const SettingsUsersDeleteModal: React.FC<Props> = ({ isOpened, onClose, userToDelete, onUserDeleted }) => {
  const intl = useScopedIntl('')
  const intlModal = useScopedIntl('settings.users.delete_modal')
  const { user } = useContext(UserContext)
  const isCurrentUser = user.getId() === userToDelete?.id
  const [isDeleting, setIsDeleting] = useState(false)

  const onSubmit = () => {
    setIsDeleting(true)
    deleteUser(
      { userId: userToDelete.id, userType: userToDelete.type },
      {
        onSuccess: () => {
          if (isCurrentUser) {
            DatacMessage.success(intlModal('user_deleted.title'), intlModal('user_deleted.description'))
            navigate(routes.signIn(AccountType.User))
          } else {
            setIsDeleting(false)
            onClose()
            onUserDeleted()
          }
        },
        onError: () => setIsDeleting(false),
        onLastAdmin: () =>
          DatacMessage.error(intlModal('error.last_admin.title'), intlModal('error.last_admin.description')),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <DatacModal
      title={intlModal('title')}
      isOpened={isOpened}
      onClose={onClose}
      onSubmit={onSubmit}
      submitLabel={intlModal('submit')}
      loading={isDeleting}
    >
      <div className="settings-users-delete-modal__body">
        {isCurrentUser
          ? intlModal('message.same_user')
          : intlModal('message.other_user', {
              user: userToDelete?.name === '-' ? userToDelete?.email : userToDelete?.name,
              email: userToDelete?.name === '-' ? '' : ` - ${userToDelete?.email}`
            })}
      </div>
    </DatacModal>
  )
}
