import './SettingsUsersLockModal.less'

import React, { useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { UserData, lockUser } from '../../../../requests'
import { DatacMessage, DatacModal } from '../../../common'

interface Props {
  isOpened: boolean
  onClose: () => void
  userToLock: UserData
  onUserLocked: () => void
}

export const SettingsUsersLockModal: React.FC<Props> = ({ isOpened, onClose, userToLock, onUserLocked }) => {
  const intl = useScopedIntl('')
  const intlModal = useScopedIntl('settings.users.lock_modal')
  const [isLocking, setIsLocking] = useState(false)

  const onSubmit = () => {
    setIsLocking(true)
    lockUser(
      { email: userToLock.email },
      {
        onSuccess: () => {
          setIsLocking(false)
          onClose()
          onUserLocked()
        },
        onRequestError: code => {
          setIsLocking(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  return (
    <DatacModal
      title={intlModal('title')}
      isOpened={isOpened}
      onClose={onClose}
      onSubmit={onSubmit}
      submitLabel={intlModal('submit')}
      loading={isLocking}
    >
      <div className="settings-users-lock-modal__body">
        {intlModal('message', { user: userToLock?.name, email: userToLock?.email })}
      </div>
    </DatacModal>
  )
}
