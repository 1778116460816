import './SettingsUsersUnlockModal.less'

import React, { useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { UserData, unlockUser } from '../../../../requests'
import { DatacMessage, DatacModal } from '../../../common'

interface Props {
  isOpened: boolean
  onClose: () => void
  userToUnlock: UserData
  onUserUnlocked: () => void
}

export const SettingsUsersUnlockModal: React.FC<Props> = ({ isOpened, onClose, userToUnlock, onUserUnlocked }) => {
  const intl = useScopedIntl('')
  const intlModal = useScopedIntl('settings.users.unlock_modal')
  const [isUnlocking, setIsUnlocking] = useState(false)

  const onSubmit = () => {
    setIsUnlocking(true)
    unlockUser(
      { email: userToUnlock.email },
      {
        onSuccess: () => {
          setIsUnlocking(false)
          onClose()
          onUserUnlocked()
        },
        onRequestError: code => {
          setIsUnlocking(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  return (
    <DatacModal
      title={intlModal('title')}
      isOpened={isOpened}
      onClose={onClose}
      onSubmit={onSubmit}
      submitLabel={intlModal('submit')}
      loading={isUnlocking}
    >
      <div className="settings-users-unlock-modal__body">
        {intlModal('message', { user: userToUnlock?.name, email: userToUnlock?.email })}
      </div>
    </DatacModal>
  )
}
