import './SettingsUsersEditModal.less'

import { Form } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CenterData, UserData, editUser } from '../../../../requests'
import { routes } from '../../../../routes'
import { validateRequired } from '../../../../validation'
import { UserContext } from '../../../auth'
import { DatacFormItem, DatacIcon, DatacMessage, DatacModal, DatacOption, DatacSelect } from '../../../common'

interface FormData {
  roleId: string
  centerIds: string[]
}

interface Props {
  isOpened: boolean
  onClose: () => void
  userToEdit: UserData
  onUserEdited: () => void
  availableRoles: DatacOption[]
  availableCenters: CenterData[]
}

export const SettingsUsersEditModal: React.FC<Props> = ({
  isOpened,
  onClose,
  userToEdit,
  onUserEdited,
  availableRoles,
  availableCenters
}) => {
  const intl = useScopedIntl('')
  const intlModal = useScopedIntl('settings.users.edit_modal')
  const { user } = useContext(UserContext)
  const [formInstance] = Form.useForm()
  const [isSaving, setIsSaving] = useState(false)
  const [isLastAdminError, setIsLastAdminError] = useState(false)

  useEffect(() => {
    if (userToEdit) {
      formInstance.setFieldsValue({
        roleId: availableRoles.find(r => r.label === userToEdit.role.name)?.value,
        centerIds: userToEdit.centers?.map(center => center.id)
      })
    }
  }, [userToEdit])

  const onSubmit = ({ roleId, centerIds }: FormData) => {
    setIsSaving(true)
    editUser(
      { userId: userToEdit.id, roleId, centerIds },
      {
        onSuccess: () => {
          if (user.getId() === userToEdit.id) {
            navigate(routes.settingsPersonalDetails)
            DatacMessage.success(intlModal('user_role_downgrade.title'), intlModal('user_role_downgrade.description'))
          } else {
            setIsSaving(false)
            onClose()
            onUserEdited()
          }
        },
        onError: () => setIsSaving(false),
        onLastAdmin: () => setIsLastAdminError(true),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const roleOptions = availableRoles.filter(role => role.value !== userToEdit?.role.name)

  const onRoleChange = () => {
    if (isLastAdminError) setIsLastAdminError(false)
  }

  return (
    <DatacModal
      isOpened={isOpened}
      onClose={onClose}
      title={intlModal('title')}
      submitLabel={intlModal('submit')}
      onSubmit={() => formInstance.submit()}
      loading={isSaving}
      afterClose={() => formInstance.resetFields()}
      destroyOnClose
    >
      <div className="settings-users-edit-modal__body">
        <div className="settings-users-edit-modal__user-info">
          <DatacIcon name="user" size="xbig" type="blue" className="settings-users-edit-modal__user-avatar" />
          <span className="settings-users-edit-modal__user-name">{userToEdit?.name}</span>
        </div>
        <Form form={formInstance} name="settings-users-edit-form" onFinish={onSubmit}>
          {(user.isSubjectRepositoryEnabled || user.isPaymentsEnabled || user.isCalendarEnabled) && (
            <DatacFormItem label={intlModal('centers.label')} name="centerIds">
              <DatacSelect
                showSearch
                mode="multiple"
                placeholder={intlModal('centers.placeholder')}
                options={availableCenters.map(center => ({ label: center.abbreviation, value: center.id }))}
              />
            </DatacFormItem>
          )}
          <DatacFormItem
            name="roleId"
            label={intlModal('role.label')}
            validate={validateRequired(intlModal('role.validation.required'))}
            error={isLastAdminError ? intlModal('role.validation.last_admin') : undefined}
          >
            <DatacSelect options={roleOptions} placeholder={intlModal('role.placeholder')} onChange={onRoleChange} />
          </DatacFormItem>
        </Form>
      </div>
    </DatacModal>
  )
}
